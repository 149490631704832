<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="View AP Other" :active="isActive" v-on:update:active="emitModalIsActive">
      <div :class="{'opacity-50 pointer-events-none': loading.initData}">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Nomor</label>
            <vs-input class="w-full" :value="data.no_ap_other" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-input class="w-full" :value="data.nama_proyek" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Rekanan</label>
            <vs-input class="w-full" :value="data.nama_rekanan" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tanggal</label>
            <vs-input class="w-full" :value="data.tgl_ap_other" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tanggal Due</label>
            <vs-input class="w-full" :value="data.tgl_due_ap_other" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" :value="data.keterangan" readonly/>
          </div>
        </div>

        <vs-divider> Detail Transaksi </vs-divider>

        <div class="h-64 overflow-y-auto">
          <vs-table :data="data.details" stripe>
            <template slot="thead">
              <vs-th class="whitespace-no-wrap">Nomor</vs-th>
              <vs-th class="whitespace-no-wrap">COA Debet</vs-th>
              <vs-th class="whitespace-no-wrap">COA Kredit</vs-th>
              <vs-th class="whitespace-no-wrap">Files</vs-th>
              <vs-th class="whitespace-no-wrap">Total</vs-th>
              <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                <vs-td>{{ item.no_termin }}</vs-td>
                <vs-td>{{ item.nama_coa_debet }}</vs-td>
                <vs-td>{{ item.nama_coa_kredit }}</vs-td>
                <vs-td>
                  <vs-button v-if="item.files_url.length > 0" color="warning" icon-pack="feather" icon="icon-eye" size="small" class="py-2 px-3 mr-2" @click="showFiles(item)">Lihat</vs-button>
                  <span v-else>-</span>
                </vs-td>
                <vs-td class="text-right">{{ item.jml_total | idr }}</vs-td>
                <vs-td>{{ item.keterangan }}</vs-td>
              </vs-tr>
              <!--footer-->
              <vs-tr v-if="data.length > 0">
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td class="text-right">{{ grandTotal | idr }}</vs-td>
                <vs-td></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>

      <FilesViewer :filesUrl="modalFiles.filesUrl" :isActive.sync="modalFiles.active"/>

    </vs-popup>
  </div>
</template>

<script>
import ApOtherRepository from '@/repositories/accounting/ap-other-repository'
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'ApOtherEdit',
  props: ['isActive', 'idApOther'],
  components: {
    FilesViewer
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      loading: {
        initData: false
      },
      modalFiles: {
        filesUrl: [],
        active: false
      },
      data: {
        details: []
      }
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.details, item => item.jml_total)
    }
  },
  methods: {
    getInitData () {
      this.loading.initData = true

      ApOtherRepository.show(this.idApOther)
        .then(response => {
          this.data = { ...this.data, ...response.data.data }
          this.data.details = _.map(this.data.details, item => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.initData = false
        })
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
